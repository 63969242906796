<template>
  <div class="main_instructions_container">
    <!-- <div>
      <img src="@/assets/new-theme/PLC-Logo.svg" alt="" />
    </div> -->
    <LogoContainer></LogoContainer>
    <div>
      <h3 class="title">
        <span class="d-block">Learning Connections</span>
        <span class="d-block">Inventory (LCI)</span>
      </h3>
      <p class="description">
        The LCI is a questionnaire used to measure the degree to which you use
        each of four Learning Patterns.<br />
        Completing the LCI is <span class="marks">the 1st step</span> beginning
        the Let Me Learn Process®.
      </p>

      <div>
        <div class="row">
          <div class="col-md-4" v-for="(item, i) in cardItems" :key="i">
            <LciCard :item="item"></LciCard>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <button @click="switchToQuestions()" class="lci_btn">
          Start the LCI
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstructionsView",
  data() {
    return {
      cardItems: [
        {
          src: require("@/assets/new-theme/icons/clock.png"),
          title: "Take the time you need",
          description:
            "While there are no right or wrong answers, there are answers that are more accurate to who you are than others."
        },
        {
          src: require("@/assets/new-theme/icons/check-circle-broken.png"),
          title: 'Avoid answering "Sometimes"',
          description:
            'Make every attempt to avoid answering "Sometimes" unless the answer truly is "Sometimes".'
        },
        {
          src: require("@/assets/new-theme/icons/target-01.png"),
          title: "Be as accurate and detailed as possible",
          description:
            "Try to be as accurate and detailed as possible in responding to the open-end questions at the end of the questionnaire."
        }
      ]
    };
  },
  methods: {
    switchToQuestions() {
      this.$router.push("/new-theme/questions");
    }
  }
};
</script>

<style lang="scss" scoped>
.main_instructions_container {
  background: #fff;
  width: 100%;
  position: relative;
  &::before {
    position: absolute;
    top: -100px;
    right: 0;
    content: "";
    background-image: url("../../../assets/new-theme/lci/background2.png");
    background-size: cover;
    width: 800px;
    height: 792px;
  }
  .title {
    color: #1d2939;
    font-weight: 600;
    font-size: 35px;
    margin-top: 50px;
  }
  .description {
    color: #475467;
    font-size: 20px;
    margin: 30px 0;
    .marks {
      font-weight: 600;
    }
  }
  .lci_btn {
    background-color: #7f56d9;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #7f56d9;
    color: #fff;
    width: 240px;
  }
}
</style>
